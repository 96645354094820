<template>
  <vs-row>
    <vs-col vs-w="12" style="height: 100%">
      <vs-tabs>
        <vs-tab label="Monthly">
          <div class="tab-text">
            <ag-grid-vue
              style="height:100%"
              ref="agGridTable"
              :gridOptions="monthly.gridOptions"
              class="ag-theme-material ag-grid-table"
              :columnDefs="monthly.columnDefs"
              :defaultColDef="monthly.defaultColDef"
              :rowData="monthly.rowData"
              rowSelection="multiple"
              colResizeDefault="shift"
              :animateRows="true"
              :suppressPaginationPanel="true"
              :enableRtl="$vs.rtl"
              :singleClickEdit="true"
              :onGridReady="onGridReadyMonthly"
            ></ag-grid-vue>
          </div>
        </vs-tab>
        <vs-tab label="Weekly" v-if="indicator=='General'">
          <div class="tab-text">
            <ag-grid-vue
              style="height:100%;"
              ref="agGridTable"
              :gridOptions="weekly.gridOptions"
              class="ag-theme-material ag-grid-table"
              :columnDefs="weekly.columnDefs"
              :defaultColDef="weekly.defaultColDef"
              :rowData="weekly.rowData"
              rowSelection="multiple"
              colResizeDefault="shift"
              :animateRows="true"
              :suppressPaginationPanel="true"
              :enableRtl="$vs.rtl"
              :onGridReady="onGridReadyWeekly"
            ></ag-grid-vue>
          </div>
        </vs-tab>
      </vs-tabs>
    </vs-col>
  </vs-row>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
export default {
  components: {
    AgGridVue
  },
  props: {
    data: {
      type: Array
    },
    totalTarget: {
      type: Number
    },
    year: {
      type: Number
    },
    indicator: {
      type: String
    },
    status: {
      type: Number
    }
  },
  data() {
    return {
      monthly: {
        searchQuery: "",
        gridOptions: {},
        maxPageNumbers: 7,
        gridApi: null,
        defaultColDef: {
          resizable: true
        },
        columnDefs: [
          {
            headerName: "Name",
            field: "name",
            width: 250,
            pinned: "left"
          },
          {
            headerName: "%",
            field: "percentage",
            width: 75,
            pinned: "left",
            editable: this.getEditable,
            onCellValueChanged: this.onChangedPercentage
          },
          {
            headerName: "Total",
            field: "total_target",
            width: 125,
            pinned: "left",
            valueFormatter: this.formatPrice
          },
          {
            headerName: "After Distribute",
            field: "total_after",
            width: 150,
            pinned: "right",
            valueFormatter: this.formatPrice
          }
        ],
        rowData: [],
        months: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December"
        ]
      },
      weekly: {
        searchQuery: "",
        gridOptions: {},
        maxPageNumbers: 7,
        gridApi: null,
        defaultColDef: {
          resizable: true,
          sortable: true
        },
        columnDefs: [
          {
            headerName: "Name",
            field: "name",
            width: 250,
            pinned: "left"
          },
          {
            headerName: "%",
            field: "percentage",
            width: 75,
            pinned: "left",
            editable: this.getEditable,
            onCellValueChanged: this.onChangedPercentage
          },
          {
            headerName: "Total",
            field: "total_target",
            width: 125,
            pinned: "left",
            valueFormatter: this.formatPrice
          },
          {
            headerName: "After Distribute",
            field: "total_after",
            width: 150,
            pinned: "right",
            valueFormatter: this.formatPrice
          }
        ],
        rowData: [],
        weeks: []
      },
      percentage: 0
    };
  },
  watch: {
    status() {},
    totalTarget() {
      if (this.weekly.rowData.length > 0) {
        this.setWeeklyTotalTarget();
      } else {
        this.setRowDataWeekly();
      }

      if (this.monthly.rowData.length > 0) {
        this.setMonthlyTotalTarget();
      } else {
        this.setRowDataMonthly();
      }
    },
    "monthly.rowData": function(val) {
      var percentageResult = [];
      var result = [];
      var months = this.monthly.months;
      val.forEach(
        function(a) {
          var monthly = [];
          months.forEach(
            function(monthName) {
              monthly.push({
                sales_quota_id: a[monthName].sales_quota_id,
                month: parseInt(
                  new Date(Date.parse(monthName + " 1, 2020")).getMonth() + 1
                ),
                amount: a[monthName].amount,
                year: a[monthName].year,
                percentage: a[monthName].percentage
              });
            },
            monthly,
            a
          );
          result.push({
            name: a.name,
            level: a.level,
            role: a.role,
            user_id: a.user_id,
            personal_id: a.personal_id,
            sales_quota_goal_id: a.sales_quota_goal_id,
            total_target: a.total_target,
            year: a.year,
            monthly: monthly
          });
          percentageResult.push({
            name: a.name,
            level: a.level,
            role: a.role,
            user_id: a.user_id,
            personal_id: a.personal_id,
            sales_quota_goal_id: a.sales_quota_goal_id,
            total_target: a.total_target,
            year: a.year,
            percentage: parseFloat(a.percentage)
          });
        },
        result,
        months
      );
      this.$emit("monthly", result);
      this.$emit("percentages", percentageResult);
    },
    "weekly.weeks": function() {
      this.setColumnDefsWeekly();
      this.setRowDataWeekly();
    },
    "weekly.rowData": function(val) {
      var result = [];
      var weeks = this.weekly.weeks;
      val.forEach(
        function(a) {
          var weekly = [];
          if (weeks) {
            weeks.forEach(
              function(item) {
                var itemData = a[item.week];
                if (itemData) {
                  weekly.push({
                    sales_quota_id: itemData.sales_quota_id,
                    week: item.week,
                    amount: itemData.amount,
                    year: itemData.year,
                    percentage: itemData.percentage,
                    days: itemData.days
                  });
                }
              },
              weekly,
              a
            );
          }
          var item = {
            name: a.name,
            level: a.level,
            role: a.role,
            user_id: a.user_id,
            personal_id: a.personal_id,
            sales_quota_goal_id: a.sales_quota_goal_id,
            total_target: a.total_target,
            weekly: weekly
          };
          result.push(item);
        },
        result,
        weeks
      );
      this.$emit("weekly", result);
    },
    data: function() {
      if (this.totalTarget > 0) {
        this.setRowDataWeekly();
        this.setRowDataMonthly();
      }
    },
    year() {
      this.getDataWeek();
    }
  },
  methods: {
    getEditable() {
      if (this.status == 0) {
        return true;
      } else {
        return false;
      }
    },
    setMonthlyPercentage(personal_id, value) {
      var months = this.monthly.months;
      var totalTarget = this.totalTarget;
      var newRowData = [];
      var percentage = 0;
      this.monthly.rowData.filter(function(item) {
        if (item.personal_id == personal_id) {
          item.percentage = value;
          item.total_target = (value / 100) * totalTarget;
          var prorateAmount = item.total_target / 12;
          var totalAfter = 0;
          months.forEach(
            function(monthItem) {
              if (monthItem == "December") {
                var amount = item.total_target - totalAfter;
                item[monthItem].amount = amount;
                totalAfter += amount;
              } else {
                item[monthItem].amount = prorateAmount;
                totalAfter += prorateAmount;
              }
            },
            item,
            totalAfter
          );
          item.total_after = totalAfter;
          newRowData.push(item);
        } else {
          newRowData.push(item);
        }
        percentage += parseFloat(item.percentage);
      }, percentage);
      this.monthly.rowData = newRowData;
      this.percentage = percentage;
    },
    setWeeklyPercentage(personal_id, value) {
      var weeks = this.weekly.weeks;
      var totalTarget = this.totalTarget;
      var newRowData = [];
      var percentage = 0;
      this.weekly.rowData.filter(function(item) {
        if (item.personal_id == personal_id) {
          item.percentage = value;
          item.total_target = (value / 100) * totalTarget;
          var totalAfter = 0;
          weeks.forEach(
            function(weekItem) {
              var prorateAmount =
                (weekItem.days / 7) * (item.total_target / weeks.length);
              if (item[weekItem.week]) {
                if (weekItem.week == "52") {
                  var amount = item.total_target - totalAfter;
                  item[weekItem.week].amount = amount;
                  totalAfter += amount;
                } else {
                  item[weekItem.week].amount = prorateAmount;
                  totalAfter += prorateAmount;
                }
              }
            },
            item,
            totalAfter
          );
          item.total_after = totalAfter;
          newRowData.push(item);
        } else {
          newRowData.push(item);
        }
        percentage += parseFloat(item.percentage);
      }, percentage);
      this.weekly.rowData = newRowData;
      this.percentage = percentage;
    },
    setMonthlyTotalTarget() {
      var months = this.monthly.months;
      var totalTarget = this.totalTarget;
      var newRowData = [];
      this.monthly.rowData.filter(function(item) {
        item.total_target = (item.percentage / 100) * totalTarget;
        // var prorateAmount = item.total_target / 12;
        var proratePercentage = 100 / 12;
        var totalAfter = 0;
        var percentageAfter = 0;
        months.forEach(
          function(monthItem) {
            var amount = 0;
            if (monthItem == "December") {
              // var amount = item.total_target - totalAfter;
              var percentage = 100 - percentageAfter;
              amount = (percentage / 100) * item.total_target;
              item[monthItem].amount = amount;
              item[monthItem].percentage = percentage;
              totalAfter += amount;
              percentageAfter += percentage;
            } else {
              amount = (proratePercentage / 100) * item.total_target;
              item[monthItem].amount = amount;
              item[monthItem].percentage = proratePercentage;
              totalAfter += amount;
              percentageAfter += proratePercentage;
            }
          },
          item,
          totalAfter
        );
        item.total_after = totalAfter;
        newRowData.push(item);
      });
      this.monthly.rowData = newRowData;
    },
    setWeeklyTotalTarget() {
      var weeks = this.weekly.weeks;
      var totalTarget = this.totalTarget;
      var newRowData = [];
      this.weekly.rowData.filter(function(item) {
        item.total_target = (item.percentage / 100) * totalTarget;
        var totalAfter = 0;
        var percentageAfter = 0;
        weeks.forEach(
          function(weekItem) {
            if (item[weekItem.week]) {
              var amount = 0;
              // var prorateAmount =
              //   (weekItem.days / 7) * (item.total_target / weeks.length);
              var proratePercentage =
                (weekItem.days / 7) * (100 / weeks.length);
              if (weekItem.week == "52") {
                var percentage = 100 - percentageAfter;
                amount = (percentage / 100) * item.total_target;
                item[weekItem.week].amount = amount;
                item[weekItem.week].percentage = percentage;
                totalAfter += amount;
                percentageAfter += proratePercentage;
              } else {
                amount = (proratePercentage / 100) * item.total_target;
                item[weekItem.week].amount = amount;
                item[weekItem.week].percentage = proratePercentage;
                totalAfter += amount;
                percentageAfter += proratePercentage;
              }
            }
          },
          item,
          totalAfter
        );
        item.total_after = totalAfter;
        newRowData.push(item);
      });
      this.weekly.rowData = newRowData;
    },
    onChangedPercentage(params) {
      var totalWeeklyPercentage = 0;
      var totalMonthlyPercentage = 0;
      this.monthly.rowData.forEach(function(item) {
        totalMonthlyPercentage += parseFloat(item.percentage);
      }, totalMonthlyPercentage);
      this.weekly.rowData.forEach(function(item) {
        totalWeeklyPercentage += parseFloat(item.percentage);
      }, totalWeeklyPercentage);

      if (totalMonthlyPercentage > 100 || totalWeeklyPercentage > 100) {
        this.$vs.dialog({
          color: "danger",
          title: "Percentage",
          text: "Percentage more than 100%",
          accept: this.acceptAlert(params.data.personal_id, params.oldValue)
        });
      } else {
        this.setMonthlyPercentage(params.data.personal_id, params.newValue);
        this.setWeeklyPercentage(params.data.personal_id, params.newValue);
      }
    },
    acceptAlert(personal_id, val) {
      this.setMonthlyPercentage(personal_id, val);
      this.setWeeklyPercentage(personal_id, val);
    },
    setRowDataMonthly() {
      var rowData = [];
      var getDataMonthly = this.getDataMonthly;
      if (this.totalTarget > 0) {
        var totalTarget = this.totalTarget;
        var monthlyTarget = totalTarget / this.data.length;
        var percentage = (monthlyTarget / totalTarget) * 100;
        // var monthlyTargetAfter = 0;
        var percentageAfter = 0;
        var data = this.data;
        this.data.forEach(
          function(a, index) {
            if (a.sales_quota_goal_id != 0) {
              monthlyTarget = a.yearly.amount;
              percentage = a.yearly.percentage;
            } else {
              if (index == data.length - 1) {
                percentage = 100 - percentageAfter;
              }
              monthlyTarget = (percentage / 100) * totalTarget;
            }
            var item = {
              name: a.name,
              level: a.level,
              role: a.role,
              personal_id: a.personal_id,
              user_id: a.user_id,
              sales_quota_goal_id: a.sales_quota_goal_id,
              total_target: monthlyTarget,
              percentage: percentage
            };
            percentageAfter += percentage;
            // monthlyTargetAfter += monthlyTarget;
            var monthlyData = getDataMonthly(a.monthly, monthlyTarget);
            rowData.push(Object.assign(item, monthlyData));
          },
          rowData,
          getDataMonthly
        );
        this.monthly.rowData = rowData;
      }
    },
    getDataMonthly(item, totalTarget) {
      var monthly = {};
      var months = this.monthly.months;
      var totalAfter = 0;
      var percentageAfter = 0;
      item.forEach(
        function(item) {
          var month = months[parseInt(item.month) - 1];
          // var amount = totalTarget / 12;
          // var percentage = (amount / totalTarget) * 100;
          var percentage = 100 / 12;
          var amount = (percentage / 100) * totalTarget;
          if (item.percentage == 0 || item.sales_quota_id == 0) {
            if (item.month == "12") {
              // amount = totalTarget - totalAfter;
              // percentage = (amount / totalTarget) * 100;
              percentage = 100 - percentageAfter;
              amount = (percentage / 100) * totalTarget;
              monthly[month] = {
                sales_quota_id: item.sales_quota_id,
                year: item.year,
                amount: amount,
                percentage: percentage
              };
            } else {
              monthly[month] = {
                sales_quota_id: item.sales_quota_id,
                year: item.year,
                amount: amount,
                percentage: percentage
              };
            }
            totalAfter += amount;
            percentageAfter += percentage;
          } else {
            monthly[month] = {
              sales_quota_id: item.sales_quota_id,
              year: item.year,
              amount: item.amount,
              percentage: item.percentage
            };
            totalAfter += item.amount;
          }
        },
        monthly,
        months,
        totalTarget,
        totalAfter
      );
      monthly["total_after"] = totalAfter;
      return monthly;
    },
    formatPrice(val) {
      val = (val.value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    setRowDataWeekly() {
      var rowData = [];
      var getDataWeekly = this.getDataWeekly;
      if (this.totalTarget > 0) {
        var totalTarget = this.totalTarget;
        var weeklyTarget = totalTarget / this.data.length;
        var percentage = (weeklyTarget / totalTarget) * 100;
        // var weeklyTargetAfter = 0
        var percentageAfter = 0;
        var data = this.data;
        this.data.forEach(
          function(a, index) {
            if (a.sales_quota_goal_id != 0) {
              weeklyTarget = a.yearly.amount;
              percentage = a.yearly.percentage;
            } else {
              if (index == data.length - 1) {
                percentage = 100 - percentageAfter;
                // weeklyTarget = totalTarget - weeklyTargetAfter
                // weeklyTarget = (percentage / 100) * totalTarget;
              }
              weeklyTarget = (percentage / 100) * totalTarget;
            }
            var item = {
              name: a.name,
              level: a.level,
              role: a.role,
              user_id: a.user_id,
              personal_id: a.personal_id,
              sales_quota_goal_id: a.sales_quota_goal_id,
              total_target: weeklyTarget,
              percentage: percentage
            };
            percentageAfter += percentage;
            // weeklyTargetAfter += weeklyTarget
            var weeklyData = getDataWeekly(a.weekly, weeklyTarget);
            rowData.push(Object.assign(item, weeklyData));
          },
          rowData,
          getDataWeekly
        );
        this.weekly.rowData = rowData;
      }
    },
    getDataWeekly(item, totalTarget) {
      var weekly = {};
      var weeks = this.weekly.weeks;
      var totalAfter = 0;
      var percentageAfter = 0;
      var dayTotal = item.length;
      item.forEach(
        function(item) {
          if (weeks) {
            var weekItem = weeks[parseInt(item.week) - 1];
            if (weekItem) {
              // var amount = (weekItem.days / 7) * (totalTarget / dayTotal);
              // var percentage = (amount / totalTarget) * 100;
              var percentage = (weekItem.days / 7) * (100 / dayTotal);
              var amount = (percentage / 100) * totalTarget;
              if (item.percentage == 0 || item.sales_quota_id == 0) {
                if (item.week == 52) {
                  // amount = totalTarget - totalAfter;
                  // percentage = (amount / totalTarget) * 100;
                  percentage = 100 - percentageAfter;
                  amount = (percentage / 100) * totalTarget;
                  weekly[weekItem.week] = {
                    sales_quota_id: item.sales_quota_id,
                    year: item.year,
                    amount: amount,
                    week: weekItem.week,
                    percentage: percentage,
                    days: weekItem.days
                  };
                } else {
                  weekly[weekItem.week] = {
                    sales_quota_id: item.sales_quota_id,
                    year: item.year,
                    amount: amount,
                    week: weekItem.week,
                    percentage: percentage,
                    days: weekItem.days
                  };
                }
                totalAfter += amount;
                percentageAfter += percentage;
              } else {
                weekly[weekItem.week] = {
                  sales_quota_id: item.sales_quota_id,
                  year: item.year,
                  amount: item.amount,
                  week: weekItem.week,
                  percentage: item.percentage,
                  days: item.days
                };
                totalAfter += item.amount;
              }
            }
          }
        },
        weekly,
        weeks,
        totalTarget,
        totalAfter
      );
      weekly["total_after"] = totalAfter;
      return weekly;
    },
    getDataWeek() {
      this.$vs.loading();
      if (this.year) {
        this.$http
          .get("/api/v1/master/target-management/week/" + this.year)
          .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              var weeks = [];
              resp.data.forEach(function(item) {
                const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                const firstDate = new Date(item.start_date);
                const secondDate = new Date(item.end_date);
                const diffDays = Math.round(
                  Math.abs((firstDate - secondDate) / oneDay)
                );
                weeks.push({
                  week: item.week,
                  days: diffDays + 1,
                  start_date: item.start_date,
                  end_date: item.end_date
                });
              }, weeks);
              this.weekly.weeks = weeks;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
          })
          .catch(error => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
    setColumnDefsWeekly() {
      var columnDefs = this.weekly.columnDefs;
      var formatPrice = this.formatPrice;
      if (this.weekly.weeks) {
        this.weekly.weeks.forEach(
          function(item) {
            columnDefs.push({
              headerName: item.week,
              field: item.week + ".amount",
              width: 125,
              valueFormatter: formatPrice
            });
          },
          columnDefs,
          formatPrice
        );
      }
      this.weekly.columnDefs = columnDefs;
    },
    setColumnDefsMonthly() {
      var columnDefs = this.monthly.columnDefs;
      var formatPrice = this.formatPrice;
      this.monthly.months.forEach(
        function(a) {
          columnDefs.push({
            headerName: a,
            field: a + ".amount",
            width: 125,
            valueFormatter: formatPrice
          });
        },
        columnDefs,
        formatPrice
      );
      this.monthly.columnDefs = columnDefs;
    },
    onGridReadyMonthly(params) {
      this.monthly.gridApi = params.api;
      this.monthly.gridApi.setDomLayout("autoHeight");
    },
    onGridReadyWeekly(params) {
      this.weekly.gridApi = params.api;
      this.weekly.gridApi.setDomLayout("autoHeight");
    }
  },
  mounted() {
    this.getDataWeek();
    this.setColumnDefsMonthly();
    this.setRowDataMonthly();
    this.percentage = 100;

    this.monthly.gridApi = this.monthly.gridOptions.api;
    this.weekly.gridApi = this.weekly.gridOptions.api;

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
  computed: {}
};
</script>