<template>
  <vx-card>
    <div class="vx-row mb-6" style="width:50%">
      <vs-button
        class="ml-4 mt-2"
        color="danger"
        icon-pack="feather"
        icon="icon-arrow-left"
        @click="handleBack()"
      >Back</vs-button>
    </div>
    <div class="vx-row" v-if="data.name">
      <vs-tabs>
        <vs-tab label="Personal Target">
          <div class="tab-text">
            <personal-target></personal-target>
          </div>
        </vs-tab>
        <vs-tab label="Distribute">
          <div class="tab-text">
            <distribute></distribute>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
    <div class="vx-row" v-else>
      <vs-tabs>
        <vs-tab label="Distribute">
          <div class="tab-text">
            <distribute></distribute>
          </div>
        </vs-tab>
      </vs-tabs>
    </div>
  </vx-card>
</template>
<script>
import Distribute from "./Distribute";
import PersonalTarget from "./PersonalTarget";
export default {
  components: {
    Distribute,
    PersonalTarget
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/v1/master/target-management/distribute",
        data: {
          salesQuotaGoalId: null,
          name: "",
          role: "",
          year: 0,
          version: "",
          indicator: "",
          totalCascade: 0,
          totalTarget: 0,
          uplift: 0,
          status: 0
        }
      };
    },
    handleBack() {
      this.$router.push({
        name: "target-management",
        query: { active: 2 }
      });
    },
    getDataSalesQuotaGoal() {
      this.$vs.loading();
      if (this.data.salesQuotaGoalId) {
        this.$http
          .get("/api/v1/master/target-management/" + this.data.salesQuotaGoalId)
          .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.dataAll = resp.data;
              this.data.name = resp.data.name;
              this.data.role = resp.data.role;
              this.data.year = resp.data.year;
              this.data.version = resp.data.version;
              this.data.indicator = resp.data.indicator;
              this.data.totalTarget = resp.data.total_target;
              this.data.totalCascade = resp.data.total_cascade;
              this.data.uplift = resp.data.uplift;
              this.data.team = resp.data.team;
              this.data.status = resp.data.status;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
          })
          .catch(error => {
            this.$vs.loading.close();
            console.log(error);
          });
      } else {
        this.$vs.loading.close();
      }
    }
  },
  mounted() {
    this.data.salesQuotaGoalId = this.$route.params.id;
    this.getDataSalesQuotaGoal();
  }
};
</script>