<template>
  <div>
    <div class="vx-row ml-1">
      <div class="vx-col w-1/2">
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Name</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            :
            <b>{{data.name}}</b>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Role</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            :
            <b>{{data.role}}</b>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Year</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            :
            <b>{{data.year}}</b>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Version</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            :
            <b>{{data.version}}</b>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Indicator</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            :
            <b>{{indicator(dataAll)}}</b>
          </div>
        </div>
      </div>
      <div class="vx-col w-1/2">
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Target</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="totalTarget" disabled />
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Uplift</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vx-input-group>
              <template slot="append">
                <div class="append-text bg-primary">
                  <span>%</span>
                </div>
              </template>
              <vs-input v-if="data.team.length>0 && data.status==0" v-model="data.uplift" />
              <vs-input v-else v-model="data.uplift" disabled />
            </vx-input-group>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Cascade</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-input class="w-full" v-model="totalCascade" disabled />
          </div>
        </div>
        <!-- <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <span>History</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-list>
              <vs-list-item  v-for="item in dataHistory" :key="item.created_at" :subtitle="item.created_at+' '+item.notes"></vs-list-item>
            </vs-list>
          </div>
        </div> -->
        <div class="vx-row mb-3" v-if="data.team.length>0 && data.status==0 ">
          <div class="vx-col sm:w-1/3 w-full"></div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-button class="mr-3 mb-2" @click="handleDistribute">Distribute</vs-button>
          </div>
        </div>
      </div>
    </div>
    <tabs-component
      :data="data.team"
      :totalTarget="data.totalCascade"
      :year="data.year"
      @weekly="setWeekly"
      @monthly="setMonthly"
      @percentages="setPercentages"
      :indicator="data.indicator"
      :status="data.status"
    ></tabs-component>
  </div>
</template>

<script>
import TabsComponent from "./Tabs";
export default {
  components: {
    TabsComponent
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        baseUrl: "/api/v1/master/target-management/distribute",
        data: {
          salesQuotaGoalId: null,
          name: "",
          role: "",
          year: 0,
          version: "",
          indicator: "",
          totalCascade: 0,
          totalTarget: 0,
          uplift: 0,
          team: [],
          status: 0
        },
        dataHistory: [],
        dataAll: null,
        monthly: [],
        weekly: [],
        percentage: 0,
        percentages: []
      };
    },
    paramData() {
      let teams = [];
      var monthly = this.monthly;
      var weekly = this.weekly;
      var percentages = this.percentages;
      this.data.team.filter(
        function(team) {
          var monthlyItem = [];
          var weeklyItem = [];
          var amount = 0;
          var percentage = 0;
          monthly.forEach(
            function(monthItem) {
              if (
                team.personal_id == monthItem.personal_id &&
                team.user_id == monthItem.user_id &&
                team.sales_quota_goal_id == monthItem.sales_quota_goal_id
              ) {
                monthlyItem = monthItem.monthly;
                amount = monthItem.total_target;
              }
            },
            team,
            teams,
            monthlyItem,
            amount
          );
          weekly.forEach(
            function(weekItem) {
              if (
                team.personal_id == weekItem.personal_id &&
                team.user_id == weekItem.user_id &&
                team.sales_quota_goal_id == weekItem.sales_quota_goal_id
              ) {
                weeklyItem = weekItem.weekly;
              }
            },
            team,
            teams,
            weeklyItem
          );

          percentages.forEach(function(item) {
            if (
              team.personal_id == item.personal_id &&
              team.user_id == item.user_id &&
              team.sales_quota_goal_id == item.sales_quota_goal_id
            ) {
              percentage = item.percentage;
            }
          }, team);
          teams.push({
            sales_quota_goal_id: team.sales_quota_goal_id,
            personal_id: team.personal_id,
            user_id: team.user_id,
            role: team.role,
            level: team.level,
            weekly: weeklyItem,
            monthly: monthlyItem,
            yearly: {
              sales_quota_id: team.yearly.sales_quota_id,
              amount: amount,
              year: team.yearly.year,
              percentage: percentage
            }
          });
        },
        monthly,
        weekly,
        teams
      );
      return {
        total_target: parseInt(this.data.totalTarget),
        total_cascade: parseInt(this.data.totalCascade),
        uplift: parseInt(this.data.uplift),
        team: teams
      };
    },
    handleDistribute() {
      if (this.percentage < 100) {
        this.$vs.dialog({
          color: "danger",
          title: "Percentage",
          text: "Percentage division total must equal 100%"
          // accept: this.acceptAlert()
        });
      } else {
        this.putData();
      }
    },
    putData() {
      this.$http
        .put(this.baseUrl + "/" + this.data.salesQuotaGoalId, this.paramData())
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Data Updated",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
            this.handleBack();
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getData() {
      this.$vs.loading();
      if (this.data.salesQuotaGoalId) {
        this.$http
          .get(this.baseUrl + "/" + this.data.salesQuotaGoalId)
          .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.dataAll = resp.data;
              this.data.name = resp.data.name;
              this.data.role = resp.data.role;
              this.data.year = resp.data.year;
              this.data.version = resp.data.version;
              this.data.indicator = resp.data.indicator;
              this.data.totalTarget = resp.data.total_target;
              this.data.totalCascade = resp.data.total_cascade;
              this.data.uplift = resp.data.uplift;
              this.data.team = resp.data.team;
              this.data.status = resp.data.status;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
          })
          .catch(error => {
            this.$vs.loading.close();
            console.log(error);
          });
      } else {
        this.$vs.loading.close();
      }
    },
    getDataHistory() {
      this.$vs.loading();
      if (this.data.salesQuotaGoalId) {
        this.$http
          .get(
            "/api/v1/master/target-management/history/" +
              this.data.salesQuotaGoalId
          )
          .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.dataHistory = resp.data;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
          })
          .catch(error => {
            this.$vs.loading.close();
            console.log(error);
          });
      } else {
        this.$vs.loading.close();
      }
    },
    setMonthly(val) {
      this.monthly = val;
    },
    setWeekly(val) {
      this.weekly = val;
    },
    formatPrice(val) {
      val = (val / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    indicator(val) {
      let indicator = "";
      if (val) {
        switch (val.indicator) {
          case "General":
            indicator = val.indicator;
            break;
          case "Distribution Channel":
            indicator = val.indicator + " (" + val.customer_category_name + ")";
            break;
          case "Product Category":
            indicator = val.indicator + " (" + val.product_category_name + ")";
            break;
          case "Product Brand":
            indicator = val.indicator + " (" + val.product_brand_name + ")";
            break;
          case "Product":
            indicator = val.indicator + " (" + val.product_name + ")";
            break;
          case "Sales Channel":
            indicator = val.indicator + " (" + val.sales_channel_name + ")";
            break;
          case "Territory Area":
            indicator =
              val.indicator +
              " (" +
              val.territory_area_code +
              " " +
              val.territory_area_name +
              ")";
            break;
        }
      }
      return indicator;
    },
    setPercentages(val) {
      this.percentages = val;
      var percentage = 0;
      val.forEach(function(v) {
        percentage += v.percentage;
      });
      this.percentage = percentage;
    },
    handleBack() {
      this.$router.push({
        name: "target-management",
        query: { active: 2 }
      });
    }
  },
  computed: {
    totalTarget: {
      get() {
        return this.formatPrice(this.data.totalTarget);
      }
    },
    totalCascade: {
      get() {
        return this.formatPrice(this.data.totalCascade);
      }
    }
  },
  mounted() {
    this.data.salesQuotaGoalId = this.$route.params.id;
    this.getData();
    this.getDataHistory();
  },
  watch: {
    "data.uplift": function(val) {
      if (val > 0) {
        this.data.totalCascade =
          this.data.totalTarget + (val / 100) * this.data.totalTarget;
      } else {
        this.data.totalCascade = this.data.totalTarget;
      }
    }
  }
};
</script>