<template>
  <div>
    <div class="vx-row ml-1">
      <div class="vx-col w-1/2">
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Name</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            :
            <b>{{data.name}}</b>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Role</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            :
            <b>{{data.role}}</b>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Year</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            :
            <b>{{data.year}}</b>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Version</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            :
            <b>{{data.version}}</b>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Indicator</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            :
            <b>{{indicator(dataAll)}}</b>
          </div>
        </div>
      </div>
      <div class="vx-col w-1/2">
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <span>Target</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            :
            <b>{{formatPrice2(data.totalTarget)}}</b>
          </div>
        </div>
        <div class="vx-row mb-3">
          <div class="vx-col sm:w-1/3 w-full">
            <span>History</span>
          </div>
          <div class="vx-col sm:w-2/3 w-full">
            <vs-list>
              <vs-list-item
                v-for="item in dataHistory"
                :key="item.created_at"
                :subtitle="item.created_at+' '+item.notes"
              ></vs-list-item>
            </vs-list>
          </div>
        </div>
      </div>
    </div>
    <vs-row>
      <vs-col vs-w="12" style="height: 100%">
        <vs-tabs>
          <vs-tab label="Monthly">
            <div class="tab-text">
              <ag-grid-vue
                style="height:100%"
                ref="agGridTable"
                :gridOptions="monthly.gridOptions"
                class="ag-theme-material ag-grid-table"
                :columnDefs="monthly.columnDefs"
                :defaultColDef="monthly.defaultColDef"
                :rowData="monthly.rowData"
                rowSelection="multiple"
                colResizeDefault="shift"
                :animateRows="true"
                :suppressPaginationPanel="true"
                :enableRtl="$vs.rtl"
                :singleClickEdit="true"
                :onGridReady="onGridReadyMonthly"
              ></ag-grid-vue>
            </div>
          </vs-tab>
          <vs-tab label="Weekly" v-if="data.indicator=='General'">
            <div class="tab-text">
              <ag-grid-vue
                style="height:100%;"
                ref="agGridTable"
                :gridOptions="weekly.gridOptions"
                class="ag-theme-material ag-grid-table"
                :columnDefs="weekly.columnDefs"
                :defaultColDef="weekly.defaultColDef"
                :rowData="weekly.rowData"
                rowSelection="multiple"
                colResizeDefault="shift"
                :animateRows="true"
                :suppressPaginationPanel="true"
                :enableRtl="$vs.rtl"
                :onGridReady="onGridReadyWeekly"
              ></ag-grid-vue>
            </div>
          </vs-tab>
        </vs-tabs>
      </vs-col>
    </vs-row>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";
export default {
  components: {
    AgGridVue
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        dataAll: null,
        dataMonthly: [],
        dataWeekly: [],
        dataHistory: [],
        data: {
          salesQuotaGoalId: null,
          name: "",
          role: "",
          year: 0,
          version: "",
          indicator: "",
          totalCascade: 0,
          totalTarget: 0,
          uplift: 0
        },
        monthly: {
          searchQuery: "",
          gridOptions: {},
          maxPageNumbers: 7,
          gridApi: null,
          defaultColDef: {
            resizable: true
          },
          columnDefs: [
            {
              headerName: "Total",
              field: "total_target",
              width: 125,
              pinned: "left",
              valueFormatter: this.formatPrice
            }
          ],
          rowData: [],
          months: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
          ]
        },
        weekly: {
          searchQuery: "",
          gridOptions: {},
          maxPageNumbers: 7,
          gridApi: null,
          defaultColDef: {
            resizable: true,
            sortable: true
          },
          columnDefs: [
            {
              headerName: "Total",
              field: "total_target",
              width: 125,
              pinned: "left",
              valueFormatter: this.formatPrice
            }
          ],
          rowData: [],
          weeks: []
        }
      };
    },
    indicator(val) {
      let indicator = "";
      if (val) {
        switch (val.indicator) {
          case "General":
            indicator = val.indicator;
            break;
          case "Distribution Channel":
            indicator = val.indicator + " (" + val.customer_category_name + ")";
            break;
          case "Product Category":
            indicator = val.indicator + " (" + val.product_category_name + ")";
            break;
          case "Product Brand":
            indicator = val.indicator + " (" + val.product_brand_name + ")";
            break;
          case "Product":
            indicator = val.indicator + " (" + val.product_name + ")";
            break;
          case "Sales Channel":
            indicator = val.indicator + " (" + val.sales_channel_name + ")";
            break;
          case "Territory Area":
            indicator =
              val.indicator +
              " (" +
              val.territory_area_code +
              " " +
              val.territory_area_name +
              ")";
            break;
        }
      }
      return indicator;
    },
    getDataSalesQuotaGoal() {
      this.$vs.loading();
      if (this.data.salesQuotaGoalId) {
        this.$http
          .get("/api/v1/master/target-management/" + this.data.salesQuotaGoalId)
          .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.dataAll = resp.data;
              this.data.name = resp.data.name;
              this.data.role = resp.data.role;
              this.data.year = resp.data.year;
              this.data.version = resp.data.version;
              this.data.indicator = resp.data.indicator;
              this.data.totalTarget = resp.data.total_target;
              this.data.totalCascade = resp.data.total_cascade;
              this.data.uplift = resp.data.uplift;
              this.data.team = resp.data.team;
              this.data.status = resp.data.status;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
          })
          .catch(error => {
            this.$vs.loading.close();
            console.log(error);
          });
      } else {
        this.$vs.loading.close();
      }
    },
    getDataPersonalDetail(salesQuotaGoalId, option) {
      this.$vs.loading();
      this.$http
        .get(
          "/api/v1/master/target-management/personal-detail/" +
            salesQuotaGoalId +
            "/" +
            option
        )
        .then(resp => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            if (option == "monthly") {
              this.dataMonthly = resp.data;
            } else if (option == "weekly") {
              this.dataWeekly = resp.data;
            }
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle"
            });
          }
        })
        .catch(error => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
    getDataWeek() {
      this.$vs.loading();
      if (this.data.year) {
        this.$http
          .get("/api/v1/master/target-management/week/" + this.data.year)
          .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              var weeks = [];
              resp.data.forEach(function(item) {
                const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
                const firstDate = new Date(item.start_date);
                const secondDate = new Date(item.end_date);
                const diffDays = Math.round(
                  Math.abs((firstDate - secondDate) / oneDay)
                );
                weeks.push({
                  week: item.week,
                  days: diffDays + 1,
                  start_date: item.start_date,
                  end_date: item.end_date
                });
              }, weeks);
              this.weekly.weeks = weeks;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
          })
          .catch(error => {
            this.$vs.loading.close();
            console.log(error);
          });
      }
    },
    setColumnDefsWeekly() {
      var columnDefs = this.weekly.columnDefs;
      var formatPrice = this.formatPrice;
      if (this.weekly.weeks) {
        this.weekly.weeks.forEach(
          function(item) {
            columnDefs.push({
              headerName: item.week,
              field: "week" + item.week,
              width: 125,
              valueFormatter: formatPrice
            });
          },
          columnDefs,
          formatPrice
        );
      }
      this.weekly.columnDefs = columnDefs;
    },
    setColumnDefsMonthly() {
      var columnDefs = this.monthly.columnDefs;
      var formatPrice = this.formatPrice;
      this.monthly.months.forEach(
        function(a) {
          columnDefs.push({
            headerName: a,
            field: a,
            width: 125,
            valueFormatter: formatPrice
          });
        },
        columnDefs,
        formatPrice
      );
      this.monthly.columnDefs = columnDefs;
    },
    onGridReadyMonthly(params) {
      this.monthly.gridApi = params.api;
      this.monthly.gridApi.setDomLayout("autoHeight");
    },
    onGridReadyWeekly(params) {
      this.weekly.gridApi = params.api;
      this.weekly.gridApi.setDomLayout("autoHeight");
    },
    setRowDataMonthly() {
      var rowData = [];
      var months = this.monthly.months;
      rowData["total_target"] = this.data.totalTarget;
      this.dataMonthly.forEach(function(item) {
        var month = months[parseInt(item.month) - 1];
        rowData[month] = item.amount;
      });
      this.monthly.rowData = [];
      this.monthly.rowData.push(rowData);
    },
    setRowDataWeekly() {
      var rowData = [];
      rowData["total_target"] = this.data.totalTarget;
      this.dataWeekly.forEach(function(item) {
        rowData["week" + item.week] = item.amount;
      });
      this.weekly.rowData = [];
      this.weekly.rowData.push(rowData);
    },
    formatPrice(val) {
      val = (val.value / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    formatPrice2(val) {
      val = (val / 1).toFixed(2).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    getDataHistory() {
      this.$vs.loading();
      if (this.data.salesQuotaGoalId) {
        this.$http
          .get(
            "/api/v1/master/target-management/history/" +
              this.data.salesQuotaGoalId
          )
          .then(resp => {
            this.$vs.loading.close();
            if (resp.code == 200) {
              this.dataHistory = resp.data;
            } else {
              this.$vs.notify({
                color: "danger",
                title: "Error",
                text: resp.message,
                position: "top-right",
                iconPack: "feather",
                icon: "icon-x-circle"
              });
            }
          })
          .catch(error => {
            this.$vs.loading.close();
            console.log(error);
          });
      } else {
        this.$vs.loading.close();
      }
    }
  },
  mounted() {
    this.data.salesQuotaGoalId = this.$route.params.id;
    this.getDataSalesQuotaGoal();
    this.getDataHistory();

    this.monthly.gridApi = this.monthly.gridOptions.api;
    this.weekly.gridApi = this.weekly.gridOptions.api;

    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector(
        ".ag-header-container"
      );
      header.style.left =
        "-" + String(Number(header.style.transform.slice(11, -3)) + 9) + "px";
    }
  },
  watch: {
    dataMonthly() {
      this.setColumnDefsMonthly();
      this.setRowDataMonthly();
    },
    dataWeekly() {
      this.setColumnDefsWeekly();
      this.setRowDataWeekly();
    },
    "weekly.weeks": function() {
      this.setColumnDefsWeekly();
      this.setRowDataWeekly();
    },
    "data.year": function() {
      this.getDataWeek();
      this.getDataPersonalDetail(this.data.salesQuotaGoalId, "monthly");
      this.getDataPersonalDetail(this.data.salesQuotaGoalId, "weekly");
    }
  }
};
</script>